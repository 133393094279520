var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"display-4 col-12"},[_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.DELIVERIES_STATE"))+" ")])])]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12"},[_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":Object.values(_vm.salesOrder.deliveryItemsState.orderedItems)}},[_c('el-table-column',{attrs:{"label":_vm.$t('SALES_ORDERS.DELIVERIES_STATE_ITEM'),"prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"delivery-item-name"},[_c('span',{staticClass:"code text-bold h3"},[_vm._v(_vm._s(row.code))]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(row.name))]),_c('span',{staticClass:"except text-muted"},[_vm._v(_vm._s(row.excerpt))])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('SALES_ORDERS.DELIVERIES_STATE_ORDERED_QUANTITY'),"prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"delivery-item-name"},[_c('span',{staticClass:"code text-bold h3"},[_vm._v(_vm._s(row.quantity))])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('SALES_ORDERS.DELIVERIES_STATE_DELIVERED_QUANTITY'),"prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"delivery-item-name"},[_c('span',{staticClass:"code text-bold h3"},[_vm._v(" "+_vm._s(_vm.salesOrder.deliveryItemsState.deliveredItems[row.id] .quantity)+" ")])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('SALES_ORDERS.DELIVERIES_STATE_REMAINING_QUANTITY'),"prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"delivery-item-name"},[_c('span',{staticClass:"code text-bold h3"},[_vm._v(" "+_vm._s(_vm.salesOrder.deliveryItemsState.remainingItems[row.id] .quantity)+" ")])])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }