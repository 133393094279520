<template>
  <div>
    <badge :type="getBadgeType()">
      {{ statusesOptions[salesOrder.status] }}
    </badge>
    <div v-if="advanced" style="display: inline">
      &nbsp;>&nbsp;

      {{ $t("COMMON.SALES_INVOICE") }}&nbsp;
      <sales-invoice-status-badge
        v-if="salesOrder.salesInvoice"
        :salesInvoice="salesOrder.salesInvoice"
      />
      <badge v-if="!salesOrder.salesInvoice" :type="'primary'">
        {{ $t("SALES_ORDERS.NOT_INVOICED") }}
      </badge>
      &nbsp;>&nbsp;

      {{ $t("COMMON.SALES_DELIVERY") }}&nbsp;
      <badge
        :type="
          salesOrder.deliveryItemsState.orderDelivered ? 'success' : 'primary'
        "
      >
        {{
          salesOrder.deliveryItemsState.orderDelivered
            ? $t("SALES_ORDERS.DELIVERED")
            : $t("SALES_ORDERS.NOT_DELIVERED")
        }}
      </badge>
    </div>
  </div>
</template>

<script>
import {
  orderStatusesOption,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_VALIDATED,
} from "@/constants/orders";
import SalesInvoiceStatusBadge from "../../SalesInvoiceManagement/partials/SalesInvoiceStatusBadge.vue";

export default {
  name: "sales-order-status-badge",

  components: { SalesInvoiceStatusBadge },

  mixins: [],

  props: ["salesOrder", "advanced"],

  data() {
    return {
      statusesOptions: orderStatusesOption,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.salesOrder.status) {
        case ORDER_STATUS_DRAFT:
          return "primary";
        case ORDER_STATUS_CANCELED:
          return "danger";
        case ORDER_STATUS_VALIDATED:
          return "default";
        case ORDER_STATUS_COMPLETED:
          return "success";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
