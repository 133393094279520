<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ salesOrder.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("SALES_ORDERS.EXPIRATION_TIME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="salesOrder.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="salesOrder.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <sales-order-status-badge
              :salesOrder="salesOrder"
              :advanced="true"
            />
          </dd>
        </dl>

        <dl class="row" v-if="salesOrder.salesInvoice">
          <dt class="col-sm-4">{{ $t("COMMON.SALES_INVOICE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesOrder.salesInvoice" />
            <sales-invoice-status-badge
              :salesInvoice="salesOrder.salesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesOrder.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="salesOrder.pricing">
            <span> {{ $formatCurrency(salesOrder.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="salesOrder.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="salesOrder.excerpt">
            <div v-html="salesOrder.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <dl
          class="row"
          v-if="
            salesOrder.billing_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_lastname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesOrder.billing_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesOrder.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ salesOrder.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesInvoiceStatusBadge from "../../SalesInvoiceManagement/partials/SalesInvoiceStatusBadge.vue";
import SalesOrderStatusBadge from "./SalesOrderStatusBadge.vue";

export default {
  name: "sales-order-view-global",

  components: {
    SalesOrderStatusBadge,
    SalesInvoiceStatusBadge,
  },

  props: ["salesOrder"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesOrder(salesOrder) {},
  },
};
</script>
